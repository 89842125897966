export const size = [650, 520]

export const plazas = [
  [23, 22, 24, 28, 42, 41, 36, 35]
]

export const nodes = [
  { x: 32, y: 512, relations: { 1: 48.76474136094644, 11: 62.36184731067546 } },
  { x: 79, y: 499, relations: { 0: 48.76474136094644, 2: 68.24954212300622, 10: 59.682493245507096 } },
  { x: 146, y: 486, relations: { 1: 68.24954212300622, 3: 50.59644256269407, 9: 68.06614430096654 } },
  { x: 194, y: 470, relations: { 2: 50.59644256269407, 4: 63.13477647065839, 8: 66.75327707311455 } },
  { x: 249, y: 439, relations: { 3: 63.13477647065839, 5: 91.09335870413386, 7: 51.088159097779204 } },
  { x: 336, y: 412, relations: { 4: 91.09335870413386, 6: 30.066592756745816, 31: 66.06814663663572 } },
  { x: 338, y: 382, relations: { 5: 30.066592756745816, 7: 86.20904824900923, 23: 37.12142238654117 } },
  { x: 252, y: 388, relations: { 4: 51.088159097779204, 6: 86.20904824900923, 8: 69.85699678629192, 22: 65.11528238439882 } },
  { x: 184, y: 404, relations: { 3: 66.75327707311455, 7: 69.85699678629192, 9: 52.20153254455275, 20: 75.92759709091287 } },
  { x: 134, y: 419, relations: { 2: 68.06614430096654, 8: 52.20153254455275, 10: 67.35725647619564, 19: 77.62087348130012 } },
  { x: 70, y: 440, relations: { 1: 59.682493245507096, 9: 67.35725647619564, 11: 56.293871780150276, 14: 74.04052944165107 } },
  { x: 15, y: 452, relations: { 0: 62.36184731067546, 10: 56.293871780150276, 12: 69.35416353759881 } },
  { x: 8, y: 383, relations: { 11: 69.35416353759881, 13: 24.166091947189145 } },
  { x: 30, y: 373, relations: { 12: 24.166091947189145, 14: 19.4164878389476, 15: 75.76938695805846 } },
  { x: 49, y: 369, relations: { 10: 74.04052944165107, 13: 19.4164878389476, 19: 69.6419413859206 } },
  { x: 59, y: 303, relations: { 13: 75.76938695805846, 16: 67.35725647619564 } },
  { x: 110, y: 259, relations: { 15: 67.35725647619564, 17: 52.32590180780452, 19: 85.09406559801923 } },
  { x: 133, y: 212, relations: { 16: 52.32590180780452, 18: 44.9221548904324, 26: 67.11929677819934 } },
  { x: 176, y: 225, relations: { 17: 44.9221548904324, 20: 105.38500842150178, 25: 60.440052945046304, 27: 66.76076692189807 } },
  { x: 114, y: 344, relations: { 9: 77.62087348130012, 14: 69.6419413859206, 16: 85.09406559801923, 20: 54.817880294662984 } },
  { x: 167, y: 330, relations: { 8: 75.92759709091287, 18: 105.38500842150178, 19: 54.817880294662984, 21: 51.86520991955976 } },
  { x: 216, y: 313, relations: { 20: 51.86520991955976, 22: 49.24428900898052, 25: 73.24616030891995 } },
  { x: 264, y: 324, relations: { 7: 65.11528238439882, 21: 49.24428900898052, 23: 79.81227975693966, 24: 77.25283166331187 } },
  { x: 341, y: 345, relations: { 6: 37.12142238654117, 22: 79.81227975693966, 30: 72.73238618387272, 35: 70.22819946431775 } },
  { x: 292, y: 252, relations: { 22: 77.25283166331187, 25: 58.855755878248644, 28: 66.76076692189807 } },
  { x: 234, y: 242, relations: { 18: 60.440052945046304, 21: 73.24616030891995, 24: 58.855755878248644, 29: 66.76076692189807 } },
  { x: 165, y: 153, relations: { 17: 67.11929677819934, 27: 31.04834939252005 } },
  { x: 195, y: 161, relations: { 18: 66.76076692189807, 26: 31.04834939252005, 29: 60.440052945046304 } },
  { x: 311, y: 188, relations: { 24: 66.76076692189807, 29: 58.855755878248644, 42: 58.309518948453004 } },
  { x: 253, y: 178, relations: { 25: 66.76076692189807, 27: 60.440052945046304, 28: 58.855755878248644, 43: 65.80273550544841 } },
  { x: 410, y: 368, relations: { 23: 72.73238618387272, 31: 41.773197148410844, 32: 63.56886030125127, 34: 71.30918594402827 } },
  { x: 402, y: 409, relations: { 5: 66.06814663663572, 30: 41.773197148410844, 51: 63.56886030125127 } },
  { x: 470, y: 389, relations: { 30: 63.56886030125127, 33: 65.96969000988257, 51: 41.773197148410844, 55: 41.43669871020132 } },
  { x: 486, y: 325, relations: { 32: 65.96969000988257, 34: 63.56099432828282, 61: 36.22154055254967 } },
  { x: 428, y: 299, relations: { 30: 71.30918594402827, 33: 63.56099432828282, 35: 66.09841147864296, 37: 70.60453243241541 } },
  { x: 365, y: 279, relations: { 23: 70.22819946431775, 34: 66.09841147864296, 36: 74 } },
  { x: 389, y: 209, relations: { 35: 74, 37: 62.03224967708329, 41: 55.54277630799526 } },
  { x: 447, y: 231, relations: { 34: 70.60453243241541, 36: 62.03224967708329, 38: 50.96076922496363, 40: 55.65968020030299 } },
  { x: 496, y: 245, relations: { 37: 50.96076922496363, 39: 56.08029957123981, 61: 44.40720662234904 } },
  { x: 517, y: 193, relations: { 38: 56.08029957123981, 40: 55.08175741568164, 50: 72.83543093852057, 59: 43.56604182158393 } },
  { x: 464, y: 178, relations: { 37: 55.65968020030299, 39: 55.08175741568164, 41: 66.12866246946176, 49: 68.8839603971781 } },
  { x: 402, y: 155, relations: { 36: 55.54277630799526, 40: 66.12866246946176, 42: 72.12489168102785, 48: 63.56099432828282 } },
  { x: 333, y: 134, relations: { 28: 58.309518948453004, 41: 72.12489168102785, 43: 63.89053137985315, 45: 60.92618484691127 } },
  { x: 272, y: 115, relations: { 29: 65.80273550544841, 42: 63.89053137985315, 44: 77.17512552629896 } },
  { x: 312, y: 49, relations: { 43: 77.17512552629896, 45: 53.53503525729669, 46: 71.02816342831905 } },
  { x: 357, y: 78, relations: { 42: 60.92618484691127, 44: 53.53503525729669, 46: 71.19691004531025, 48: 60.876925020897694 } },
  { x: 370, y: 8, relations: { 44: 71.02816342831905, 45: 71.19691004531025, 47: 56.515484603779164 } },
  { x: 425, y: 21, relations: { 46: 56.515484603779164, 48: 72.56031973468694 } },
  { x: 416, y: 93, relations: { 41: 63.56099432828282, 45: 60.876925020897694, 47: 72.56031973468694, 49: 66.48308055437865 } },
  { x: 480, y: 111, relations: { 40: 68.8839603971781, 48: 66.48308055437865, 50: 49.03060268852505 } },
  { x: 528, y: 121, relations: { 39: 72.83543093852057, 49: 49.03060268852505, 60: 24 } },
  { x: 462, y: 430, relations: { 31: 63.56886030125127, 32: 41.773197148410844, 52: 41.617304093369626 } },
  { x: 496, y: 454, relations: { 51: 41.617304093369626, 53: 56.586217403180434 } },
  { x: 535, y: 413, relations: { 52: 56.586217403180434, 54: 29 } },
  { x: 535, y: 384, relations: { 53: 29, 55: 24.020824298928627 } },
  { x: 511, y: 383, relations: { 32: 41.43669871020132, 54: 24.020824298928627, 56: 81.98780397107853 } },
  { x: 552, y: 312, relations: { 55: 81.98780397107853, 57: 27.586228448267445 } },
  { x: 532, y: 293, relations: { 56: 27.586228448267445, 58: 72.89718787443039, 61: 42.190046219457976 } },
  { x: 565, y: 228, relations: { 57: 72.89718787443039, 59: 42.2965719651132 } },
  { x: 560, y: 186, relations: { 39: 43.56604182158393, 58: 42.2965719651132, 60: 65.49045732013177 } },
  { x: 552, y: 121, relations: { 50: 24, 59: 65.49045732013177 } },
  { x: 490, y: 289, relations: { 33: 36.22154055254967, 38: 44.40720662234904, 57: 42.190046219457976 } }
]
